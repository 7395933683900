/* eslint-disable react-hooks/exhaustive-deps */
import { RedoIcon } from '@fluentui/react-icons-northstar';
import { Accordion, Button, Loader, Popup, Segment, Table } from '@fluentui/react-northstar';
import { formatDate } from 'helpers/utils';
import { useMemo } from 'react';
import { JobUserSyncSource, JobUserSyncStatus } from 'types';
import { useAppSettings } from './Context';

const getJobSyncStatusLabel = (jobStatus: JobUserSyncStatus): string => {
  switch (jobStatus) {
    case JobUserSyncStatus.Pending:
      return 'Waiting';
    case JobUserSyncStatus.Failed:
      return 'Failed';
    case JobUserSyncStatus.Success:
      return 'Success';
    case JobUserSyncStatus.InProgress:
      return 'In Progress';
    default:
      return '';
  }
};

const getJobSyncStatusColor = (jobStatus: JobUserSyncStatus): string => {
  switch (jobStatus) {
    case JobUserSyncStatus.Pending:
      return '#333333';
    case JobUserSyncStatus.Failed:
      return '#a61717';
    case JobUserSyncStatus.Success:
      return '#215718';
    case JobUserSyncStatus.InProgress:
      return '#182857';
    default:
      return '';
  }
};

const getJobSourceLabel = (source: JobUserSyncSource): string => {
  switch (source) {
    case JobUserSyncSource.Api: return 'API';
    default: return 'Worker';
  }
};

const JobSyncHistory = () => {
  const {
    tenantData,
    tenantDataLoading,
    isSyncLoading,
    reprocessingJobs,
    reprocessJob,
    startSync,
  } = useAppSettings();

  const headers = [
    'Source',
    'Status',
    'Created date',
    'Queued date',
    'Completed date',
  ];

  const jobHistory = useMemo(() => {
    if (!tenantData) return [];
    return tenantData.jobs;
  }, [tenantData]);

  const handleReprocessJob = async (jobId: string) => {
    if (!jobId || reprocessingJobs.length > 0) return;
    await reprocessJob(jobId);
  };

  const panels = useMemo(() => {
    return jobHistory.map((item, index) => {
      const rows = item.jobs.map((job, i) => {
        return {
          key: i + 1,
          items: [
            {
              key: 'source',
              styles: { width: 80 },
              content: getJobSourceLabel(job.source),
            },
            {
              key: 'status',
              content: (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: reprocessingJobs?.length > 0
                      ? '#333333'
                      : getJobSyncStatusColor(job.status),
                  }}
                >
                  {job.status === JobUserSyncStatus.Failed && (
                    <span
                      style={{
                        cursor: reprocessingJobs?.length > 0 ? 'initial' : 'pointer',
                        width: 20,
                        marginRight: 8,
                      }}
                      onClick={() => handleReprocessJob(job.id)}
                    >
                      {reprocessingJobs.includes(job.id) ? <Loader size="smallest" /> : <RedoIcon />}
                    </span>
                  )}
                  {job.errorMessage ? (
                    <Popup
                      content={job.errorMessage}
                      trigger={<span>{getJobSyncStatusLabel(job.status)}</span>}
                    />
                  ) : (
                    <>
                      {getJobSyncStatusLabel(job.status)}
                    </>
                  )}
                </div>
              ),
            },
            {
              key: 'createdDate',
              content: formatDate(job.dateCreated),
            },
            {
              key: 'queuedDate',
              content: formatDate(job.dateQueued, 'Not yet queued'),
            },
            {
              key: 'completedDate',
              content: formatDate(job.dateCompleted, 'Not yet completed'),
            },
          ],
        };
      });

      const formattedDate = formatDate(item.lastCompletedDate);

      return {
        key: index + 1,
        title: `Job sync at ${formattedDate}`,
        content: (
          <Table
            compact
            header={headers}
            rows={rows}
            style={{ minHeight: 'auto' }}
          />
        ),
      };
    });
  }, [jobHistory, reprocessingJobs?.length]);

  if (!panels) return null;

  return (
    <Segment color="brand" style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>
          Job Sync History
        </h2>
        <Button primary onClick={startSync} disabled={isSyncLoading}>
          Start a sync
        </Button>
      </div>
      {tenantDataLoading
        ? <Loader />
        : <Accordion panels={panels} />
      }
      {jobHistory.length === 0 && !tenantDataLoading && !isSyncLoading && (
        <p>No job sync history available</p>
      )}
      {isSyncLoading && <Loader />}
    </Segment>
  );
};

export default JobSyncHistory;
