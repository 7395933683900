import { Box, Button, Checkbox, Flex, Form as FluentForm, Status as FluentStatus, FormInput, FormLabel, Loader, Segment } from '@fluentui/react-northstar';
import { useMutation } from 'hooks/useMutation';
import { useQuery } from 'hooks/useQuery';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTenantSettings, isServicePrincipalCredentialsHaveAccess, isServicePrincipalCredentialsValid, updateTenantSettings } from 'redux/services/tenantsApi';
import { LabelType, TenantServicePrincipalSettingsState } from 'types';
import { SaveTenantSettingsRequest, TenantSettings } from 'types/tenants';
import Icon from 'components/Onboarding/Status/Icon';
import { authSlice } from 'redux/slices/auth';
import { useAppSettings } from './Context';

type StepStatus = 'done' | 'failed' | 'ongoing' | 'pending';
type StatusProps = {
  validCheck: StepStatus;
  validLabel: string;
  accessCheck: StepStatus;
  accessLabel: string;
};

const getColorStatus = (status: StepStatus): string => {
  switch (status) {
    case 'done':
      return 'green';
    case 'failed':
      return 'red';
    case 'ongoing':
      return 'orange';
    default:
      return 'grey';
  }
};

const getStatusType = (isValid: boolean, isPending?: boolean, isBusy?: boolean): StepStatus => {
  if (isPending) return 'pending';
  if (isBusy) return 'ongoing';
  return isValid ? 'done' : 'failed';
};

const Label = ({ title }: LabelType) => (
  <>
    {title}:<span style={{ color: '#c4314b' }}></span>
  </>
);

const Status = ({ validCheck, validLabel, accessCheck, accessLabel }: StatusProps) => (
  <Flex.Item grow align="center">
    <Flex column gap="gap.medium" style={{ marginBottom: 16 }}>
      <Flex vAlign="center">
        <FluentStatus
          color={getColorStatus(validCheck)}
          size="larger"
          icon={<Icon status={validCheck} />}
          style={{ marginRight: 8, minWidth: 16, minHeight: 16 }}
        />
        {validLabel}
      </Flex>
      <Flex vAlign="center">
        <FluentStatus
          color={getColorStatus(accessCheck)}
          size="larger"
          icon={<Icon status={accessCheck} />}
          style={{ marginRight: 8, minWidth: 16, minHeight: 16 }}
        />
        {accessLabel}
      </Flex>
    </Flex>
  </Flex.Item>
);

const ServicePrincipalSettings = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { tenantData, refreshTenantData, tenantDataLoading } = useAppSettings();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [form, setForm] = useState<TenantSettings & { enabled?: boolean; }>({});
  const [dirtyFields, setDirtyFields] = useState<{ [key: string]: boolean }>({});
  const [credentialError, setCredentialError] = useState<string>();
  const [accessError, setAccessError] = useState<string>();
  const [saveError, setSaveError] = useState<string>();

  const { data, refetch, loading } = useQuery(() => {
    if (tenantData?.msTenantId) return getTenantSettings(tenantData.msTenantId);
    return Promise.resolve({} as TenantSettings);
  });

  const { mutate: updateCredentials, loading: isSaving } = useMutation<SaveTenantSettingsRequest, boolean>({
    emptyResponse: true,
    callback: async (req: SaveTenantSettingsRequest): Promise<boolean> => {
      return updateTenantSettings(req.tenantId, req.payload);
    },
    onSuccess: (result) => {
      if (!result && form.enabled) {
        setSaveError('Failed to save tenant settings');
        return;
      }
      resetForm();
    },
    onFailure: (error) => {
      setSaveError(error);
    },
  });

  const {
    data: isCredentialsValid,
    mutate: checkIsCredentialsValid,
    loading: isValidatingCredentials,
  } = useMutation<SaveTenantSettingsRequest, boolean>({
    callback: async (req: SaveTenantSettingsRequest): Promise<boolean> => {
      return isServicePrincipalCredentialsValid(req.tenantId, req.payload);
    },
  });

  const {
    data: isCredentialsHaveAccess,
    mutate: checkIsCredentialsHaveAccess,
    loading: isValidatingAccess,
  } = useMutation<SaveTenantSettingsRequest, boolean>({
    callback: async (req: SaveTenantSettingsRequest): Promise<boolean> => {
      return isServicePrincipalCredentialsHaveAccess(req.tenantId, req.payload);
    },
  });

  const isBusy = isSaving
    || isValidatingCredentials
    || isValidatingAccess
    || loading;

  const isSaveDisabled = isBusy || Object.keys(dirtyFields).length === 0;

  const credentialsStatus = useMemo(() => {
    if (!isFormSubmitted) return 'pending';
    return getStatusType(isCredentialsValid, false, isValidatingCredentials);
  }, [isFormSubmitted, isCredentialsValid, isValidatingCredentials]);

  const accessStatus = useMemo(() => {
    if (!isFormSubmitted) return 'pending';
    return getStatusType(isCredentialsHaveAccess, isValidatingCredentials, isValidatingAccess);
  }, [isFormSubmitted, isCredentialsHaveAccess, isValidatingCredentials, isValidatingAccess]);

  const credentialsLabel = useMemo(() => {
    if (!isFormSubmitted) return '';
    if (isValidatingCredentials) return 'Checking if credentials are valid...';
    if (isCredentialsValid) return 'The credentials are valid';
    return credentialError || 'Invalid credentials';
  }, [isValidatingCredentials, isCredentialsValid, credentialError]);

  const accessLabel = useMemo(() => {
    if (!isFormSubmitted) return '';
    if (isValidatingAccess) return 'Checking if service principal has correct permissions...';
    if (isCredentialsHaveAccess) return 'The service principal has correct permissions';
    return accessError || 'The service principal does not have sufficient permissions';
  }, [isValidatingAccess, isCredentialsHaveAccess, accessError]);

  const showStatus = useMemo(() => {
    if (!isFormSubmitted || !form.enabled) return false;
    return isValidatingCredentials
      || isValidatingAccess
      || !!credentialError
      || !!accessError
      || isSaving;
  }, [isFormSubmitted, isValidatingCredentials, isValidatingAccess, credentialError, accessError, isSaving]);

  useEffect(() => {
    if (!tenantData?.msTenantId) return;
    refetch();
    dispatch(authSlice.actions.setTenantData(tenantData));
  }, [tenantData?.msTenantId]);

  useEffect(() => {
    let enabled = false;
    if (tenantData?.servicePrincipalState === TenantServicePrincipalSettingsState.Mandatory) {
      enabled = true;
    } else {
      enabled = tenantData?.servicePrincipalState === TenantServicePrincipalSettingsState.Optional;
    }

    setForm({
      clientId: data?.clientId || '',
      clientSecret: data?.clientSecret || '',
      enabled,
    });
    resetForm();
  }, [data, tenantData]);

  useEffect(() => {
    refreshTenantData();
  }, []);

  useEffect(() => {
    if (!tenantData) return;
    switch (credentialsStatus) {
      case 'done':
        setCredentialError('');
        checkIsCredentialsHaveAccess({ tenantId: tenantData.msTenantId, payload: form });
        break;
      case 'failed':
        setCredentialError('Invalid credentials');
        break;
      default:
        break;
    }
  }, [credentialsStatus]);

  useEffect(() => {
    if (!tenantData) return;
    switch (accessStatus) {
      case 'done':
        setAccessError('');
        updateCredentials({ tenantId: tenantData.msTenantId, payload: form });
        break;
      case 'failed':
        setAccessError('The service principal does not have sufficient permissions');
        break;
      default:
        break;
    }
  }, [accessStatus]);

  const handleTextChange = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const { name, value } = e.target as HTMLInputElement;
    setForm({ ...form, [name]: value } as TenantSettings);
    setDirtyFields({ ...dirtyFields, [name]: true });
  };

  const handleEnableChanged = () => {
    setForm({ ...form, enabled: !form.enabled });
    setDirtyFields({ ...dirtyFields, enabled: true });
  };

  const resetForm = () => {
    setIsFormSubmitted(false);
    setCredentialError('');
    setAccessError('');
    setSaveError('');
    setDirtyFields({});
  };

  const handleSubmit = async () => {
    if (!tenantData?.msTenantId) return;
    setIsFormSubmitted(true);
    setSaveError('');
    if (form.enabled) {
      await checkIsCredentialsValid({ tenantId: tenantData.msTenantId, payload: form });
    } else {
      updateCredentials({ tenantId: tenantData.msTenantId, payload: form });
    }
  };

  return (
    <Segment color="brand" style={{ width: '100%' }}>
      <h2>Service Principal Settings</h2>
      {tenantDataLoading ? <Loader /> : (
        <div style={{ maxWidth: 300 }}>
          <Box style={{ marginBottom: '1.5rem' }}>
            <Checkbox
              checked={form.enabled}
              label="Enabled?"
              disabled={tenantData?.servicePrincipalState === TenantServicePrincipalSettingsState.Mandatory}
              style={{
                margin: '3px 0',
                display: 'grid',
              }}
              onClick={handleEnableChanged}
            />
          </Box>
          <Box style={{ marginBottom: '1.5rem' }}>
            <FormLabel content={<Label title="Client ID" />} style={{ marginBottom: '0.5rem' }} />
            <FormInput
              onChange={handleTextChange}
              placeholder="Client ID"
              name="clientId"
              value={form?.clientId}
              disabled={!form.enabled}
              fluid
            />
          </Box>
          <Box style={{ marginBottom: '3rem' }}>
            <FormLabel content={<Label title="Client Secret" />} style={{ marginBottom: '0.5rem' }} />
            <FormInput
              onChange={handleTextChange}
              placeholder="Client Secret"
              name="clientSecret"
              value={form?.clientSecret}
              disabled={!form.enabled}
              fluid
            />
          </Box>
          {showStatus && (
            <Status
              validCheck={credentialsStatus}
              accessCheck={accessStatus}
              validLabel={credentialsLabel}
              accessLabel={accessLabel}
            />
          )}
          {saveError && (
            <Flex vAlign="center" style={{ marginBottom: 16 }}>
              <FluentStatus
                color="red"
                size="larger"
                icon={<Icon status="failed" />}
                style={{ marginRight: 8, minWidth: 16, minHeight: 16 }}
              />
              {saveError}
            </Flex>
          )}
          <Box style={{ marginBottom: '1rem', marginTop: 0 }}>
            <Button
              content={
                <Flex gap="gap.small">
                  {isBusy && <Loader size="smallest" />}
                  <p>Save</p>
                </Flex>
              }
              disabled={isSaveDisabled}
              primary
              onClick={handleSubmit}
              fluid
              data-testid="btn-save"
            />
          </Box>
        </div>
      )}
    </Segment>
  );
};

export default ServicePrincipalSettings;
