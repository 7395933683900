/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';

export type MutationResponse<TR, TS> = {
  loading?: boolean;
  error?: string;
  data: TS;
  mutate: (req: TR) => Promise<void>;
};

export type UseMutationProps<TR, TS> = {
  emptyResponse?: boolean;
  onSuccess?: (res: TS) => void;
  onFailure?: (err: string) => void;
  callback: (req: TR) => Promise<TS>;
};

/**
 * Helper hook to handle posting of async data
 * @param props UseMutationProps { onSuccess, onFailure, callback }
 * @returns { loading: boolean, error: string, data: T }
 */
export function useMutation<TR, TS>(props: UseMutationProps<TR, TS>): MutationResponse<TR, TS> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<TS>();

  const mutate = async (payload: TR) => {
    if (loading) return;
    setLoading(true);
    setData(undefined);
    setError(undefined);
    try {
      const result = await props.callback(payload);
      if (!result) throw new Error('No data returned');
      setData(result);
      setLoading(false);
      if (props.onSuccess) props.onSuccess(result);
    } catch (err) {
      const errorMessage = (err as Error)?.message ?? err;
      setError(errorMessage);
      setLoading(false);
      if (props.onFailure) props.onFailure(errorMessage);
    }
  };

  return {
    loading,
    error,
    data,
    mutate,
  } as MutationResponse<TR, TS>;
}
