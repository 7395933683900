/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, ExclamationTriangleIcon, ShorthandCollection, DropdownItemProps } from '@fluentui/react-northstar';
import { CallerId, DecodedErrorMessage, DropdownItemType, TenantDialPlan, User } from 'types';
import { msalConfig } from 'authConfig';
import * as microsoftTeams from '@microsoft/teams-js';

type Dictionary = { [key: string]: string };

export function decodeErrorMsg<T>(data: string): T | DecodedErrorMessage {
  try {
    const jsonData = JSON.parse(data) as T;
    return jsonData;
  } catch (e) {
    return { short_description: data, error_description: data };
  }
}

export const deepRefresh = (): void =>
  microsoftTeams.executeDeepLink(`https://teams.microsoft.com/l/entity/${msalConfig.auth.teamAppId}/index`);

export const getHeaders = (token?: string): Headers => {
  const headers = new Headers();
  if (!token) return headers;
  const bearer = `Bearer ${token}`;
  headers.append('Authorization', bearer);
  addTenantHeader(headers);
  return headers;
};

export const getHeadersObject = (token?: string): Dictionary => {
  const headers: Dictionary = {
    'Content-Type': 'application/json',
  };
  if (token) headers.Authorization = `Bearer ${token}`;
  addTenantHeader(headers);
  return headers;
};

export const getInitials = (email?: string): string => {
  if (!email) return 'N/A';
  const name = email.split('@')[0].toUpperCase();

  if (name.includes('.')) {
    const slicedName = name.split('.');
    return `${slicedName[0]} ${slicedName[1]}`;
  }

  return `${name}`;
};

export function flattenObject<T>(obj: T): T {
  const flattened: any = {};
  if (typeof obj !== 'object' || obj == null) return obj;
  Object.keys(obj).forEach((key) => {
    const k = key as keyof T;
    if (typeof obj[k] === 'object' && obj[k] !== null) {
      Object.assign(flattened, flattenObject(obj[k] as any));
    } else {
      flattened[key] = obj[k];
    }
  });
  return flattened;
}

export const transformToDropdownOptions = (
  items: { status: number; name: string }[],
): ShorthandCollection<DropdownItemProps> => {
  if (!Array.isArray(items)) return [];
  return items.map(({ name, status }: { name: string; status: number }) => {
    return {
      description: name,
      content: (
        <>
          <Flex space="between">
            {name}{' '}
            {status === 2 && (
              <Flex>
                <ExclamationTriangleIcon outline />
              </Flex>
            )}
          </Flex>
        </>
      ),
    };
  });
};

export const setCallerIdOptions = (items?: CallerId[]): ShorthandCollection<DropdownItemProps> => {
  if (!Array.isArray(items)) return [];

  const result = items
    ?.filter(
      ({ callerIdPolicy }: { callerIdPolicy: string }) =>
        !['Global', 'Display-Number', 'Anonymous', 'Optional-Anonymous'].includes(callerIdPolicy),
    )
    ?.map(({ callerIdPolicy }: { callerIdPolicy: string }) => {
      return {
        description: callerIdPolicy,
        content: (
          <>
            <Flex space="between">{callerIdPolicy}</Flex>
          </>
        ),
      };
    });

  result.unshift(
    { description: 'Display-Number', content: <Flex space="between">Display-Number</Flex> },
    { description: 'Anonymous', content: <Flex space="between">Anonymous</Flex> },
    { description: 'Optional-Anonymous', content: <Flex space="between">Optional-Anonymous</Flex> },
  );

  return result;
};

export const transformToDialPlanOptions = (
  items: TenantDialPlan[],
): ShorthandCollection<DropdownItemProps> | DropdownItemType[] => {
  if (!Array.isArray(items)) return [];
  return items.map(({ simpleName, status }: { simpleName: string; status: number }) => {
    return {
      description: simpleName,
      content: (
        <>
          <Flex space="between">
            {simpleName}{' '}
            {status === 2 && (
              <Flex>
                <ExclamationTriangleIcon outline />
              </Flex>
            )}
          </Flex>
        </>
      ),
    };
  });
};

export const upsertData = (items: User[], item: User): void => {
  const index = items.findIndex((_item: User) => _item.userPrincipalName === item.userPrincipalName);

  if (index > -1) {
    items[index] = item;
  } else {
    items.push(item);
  }
};

export const getTeamsToken = (): string | undefined => {
  if (!window?.localStorage) return undefined;
  const item = window.localStorage.getItem('SYMBIO_MS_TOKEN');
  if (!item) return undefined;
  return item;
};

export const addTenantHeader = (headers: Headers | { [key: string]: string }) => {
  const xTenant = getTenant();
  if (!xTenant) return;

  if (headers instanceof Headers) {
    headers.append('X-Tenant', xTenant);
  } else {
    headers['X-Tenant'] = xTenant;
  }
};

export const getTenant = (): string | undefined => {
  if (!window?.localStorage) return undefined;
  const item = window.localStorage.getItem('XTENANT');
  if (!item) return undefined;
  return item;
};

export const getBaseTenant = (): string | undefined => {
  if (!window?.localStorage) return undefined;
  const item = window.localStorage.getItem('BASETENANT');
  if (!item) return undefined;
  return item;
};

export function formatDate(dateString?: string, defaultLabel = 'N/A'): string {
  if (!dateString) return defaultLabel;
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}
