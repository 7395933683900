/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import useGetTenant from 'hooks/useGetTenant';
import { useMutation } from 'hooks/useMutation';
import React, { useContext, useEffect } from 'react';
import { startServicePrincipalSync, reprocessJob as reprocessJobApi } from 'redux/services/userSyncJobApi';
import { TenantData } from 'types';

type AppSettingsProps = {
  tenantData?: TenantData;
  tenantDataLoading?: boolean;
  isSyncLoading?: boolean;
  reprocessingJobs: string[];
  reprocessJob: (jobId: string) => Promise<void>;
  refreshTenantData: () => void;
  startSync: () => Promise<void>;
};

const AppSettingsContext = React.createContext<AppSettingsProps>({
  reprocessingJobs: [],
  reprocessJob: async () => { },
  refreshTenantData: () => { },
  startSync: async () => { },
});

const useAppSettings = () => {
  const context = useContext(AppSettingsContext);
  if (context === undefined) {
    throw new Error('useAppSettings must be used within a AppSettingsContextProvider');
  }
  return context;
};

const AppSettingsContextProvider: React.FC = ({ children }) => {
  const [reprocessingJobs, setReprocessingJobs] = React.useState<string[]>([]);
  const { data: tenantData, refetch: refreshTenantData, loading: tenantDataLoading } = useGetTenant();
  const { mutate: startSync, loading: isSyncLoading } = useMutation({
    emptyResponse: true,
    callback: (tenantId: string) => startServicePrincipalSync(tenantId),
    onSuccess: () => {
      refreshTenantData();
    },
  });
  const { mutate: reprocessJob } = useMutation({
    callback: (jobId: string) => {
      setReprocessingJobs((prev) => [...prev, jobId]);
      return reprocessJobApi(jobId);
    },
    onSuccess: (jobId: string) => {
      setReprocessingJobs((prev) => prev.filter((item) => item !== jobId));
      setTimeout(() => refreshTenantData(), 0);
    },
    onFailure: (jobId: string) => {
      setReprocessingJobs((prev) => prev.filter((item) => item !== jobId));
      setTimeout(() => refreshTenantData(), 0);
    },
  });

  const handleStartSync = async () => {
    if (!tenantData) return;
    await startSync(tenantData.id);
  };

  useEffect(() => {
    refreshTenantData();
  }, []);

  return (
    <AppSettingsContext.Provider
      value={{
        tenantData,
        tenantDataLoading,
        isSyncLoading,
        reprocessingJobs,
        reprocessJob,
        refreshTenantData,
        startSync: handleStartSync,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export { AppSettingsContext, AppSettingsContextProvider, useAppSettings };
