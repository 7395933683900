export type User = {
  displayName?: string;
  userPrincipalName?: string;
  onlineVoiceRoutingPolicy?: string;
  alias?: string;
  tenantDialPlan?: string;
  interpretedUserType?: string;
  onPremLineURI?: string;
  identity?: string;
  tenantId?: string;
  dirtyProps?: string[];
  assignedPlan?: string[];
  featureTypes?: string[];
  voiceLicenseStatus?: number;
  callerIdPolicy?: string;
  status?: number;
};

export type ManageFilter = {
  status: { unmodified: number; pending: number; success: number; errored: number };
  identityType: { users: number; resource: number };
  number: { withNumber: number; withoutNumber: number };
  voiceRoute: { withVR: number; withoutVR: number };
  dialPlan: { withDP: number; withoutDP: number };
};

export type TenantDialPlan = {
  identity: string;
  name: string;
  simpleName: string;
  description: string;
  status: number;
};

export type VoiceRoutePolicy = {
  identity: string;
  name: string;
  description: string;
  status: number;
};

export type TenantNumber = {
  name: string;
  id: string;
  status: number;
  isAssigned: boolean;
  number: string;
};

export type DownloadTenantStatusResponse = {
  status: number;
  blob?: Blob;
};

export type TenantTheme = {
  logoUrl?: string;
  loginImageUrl?: string;
  appName?: string;
  primaryColor?: string;
  secondaryColor?: string;
};

export enum TenantServicePrincipalSettingsState {
  None = 0,
  Optional = 1,
  Mandatory = 2,
}

export enum JobUserSyncStatus {
  Pending = 0,
  Failed = 1,
  Success = 2,
  InProgress = 3
}

export enum JobUserSyncSource {
  Api = 0,
  Worker = 1,
}

export type JobUserSync = {
  id: string;
  tenantId: string;
  status: JobUserSyncStatus;
  groupId: string;
  skip: number;
  limit: number;
  isSoftDeleted: boolean;
  retries?: number;
  dateQueued?: string;
  dateCreated?: string;
  dateCompleted?: string;
  source: JobUserSyncSource;
  errorMessage?: string;
};

export type JobUserSyncGrouped = {
  groupId: string;
  jobs: JobUserSync[];
  lastCompletedDate?: string;
  totalJobs: number;
};

export type TenantData = {
  serviceType?: string;
  status?: number;
  tenantId: string;
  theme?: TenantTheme;
  id: string;
  msTenantId: string;
  isServicePrincipalCredentialsSaved?: boolean;
  servicePrincipalState?: TenantServicePrincipalSettingsState;
  jobs: JobUserSyncGrouped[];
};
