import { useMsal } from '@azure/msal-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { checkConfigDifferences } from 'redux/services/tenantsApi';
import { RootState } from 'redux/store';
import { baseUrl } from '../../authConfig';
import { getHeaders } from '../../helpers/utils';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useGetDialPlansMutation } from '../../redux/services/dialPlanApi';
import { useGetIndentitiesMutation } from '../../redux/services/identityApi';
import { useGetNumbersMutation } from '../../redux/services/numberApi';
import { useGetVoiceRoutePoliciesMutation } from '../../redux/services/voicePolicyApi';
import { ConfigChanges, User } from '../../types';
import { publishToolbarStyles } from './Manage.styles';
import ManagePublishToolbar from './ManagePublishToolbar';
import ManageTable from './ManageTable';
import ManageToolbar from './ManageToolbar';
import { initialFilter } from './constants';
import ManageContext from './context';
import { AppContext } from 'contexts/AppContext';
import { useHistory } from 'react-router-dom';

type ManageType = { theme: string | undefined };

const Manage = ({ theme }: ManageType): React.ReactElement => {
  const { instance, accounts } = useMsal();
  const { token } = useSelector((state: RootState) => state.auth);
  const { isMobile } = useIsMobileView();
  const history = useHistory();

  useEffect(() => {
    if (accounts) {
      instance.setActiveAccount(accounts[0]);
    }
  }, [accounts, instance]);

  const [filters, setFilters] = useState(initialFilter);
  const [filteredNumbers, setFilteredNumbers] = useState([]);
  const [identityData, setIdentityData] = useState<User>({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modifiedData, setModifiedData] = useState({ data: [] });
  const [searchKey, setSearchKey] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileSuccess, setIsMobileSuccess] = useState(false);
  const [isResettingCache, setIsResettingCache] = useState(false);
  const [isStateFreeze, setIsStateFreeze] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [compareResults, setCompareResults] = useState<ConfigChanges | null>(null);

  const [getDialPlans, { data: dialPlans, isLoading: isDialPlanLoading }] = useGetDialPlansMutation();
  const [getIdentities, { data: identities, isLoading: isIdentitiesLoading, error: identitiesError }] =
    useGetIndentitiesMutation();
  const [getNumbers, { data: numbers, isLoading: isNumbersLoading }] = useGetNumbersMutation();
  const [getVoiceRoutePolicies, { data: voiceRoutePolicies, isLoading: isVoiceRouteLoading }] =
    useGetVoiceRoutePoliciesMutation();

  const getManageInitialData = useCallback(() => {
    getDialPlans({ token });
    getVoiceRoutePolicies({ token });
  }, [getDialPlans, getVoiceRoutePolicies, token]);

  const refreshData = () => {
    setSearchKey('');
    setCurrentPage(1);
    setFilters(initialFilter);
    getDialPlans({ token });
    getVoiceRoutePolicies({ token });
    getIdentities({
      token,
      body: {
        page: 1,
        limit: 10,
        searchKey: undefined,
        identityFilter: [0, 1],
        numberFilter: [0, 1],
        voiceRouteFilter: [0, 1],
        dialPlanFilter: [0, 1],
        licenseFilter: [0, 1],
      },
    });
  };

  const resetCache = async () => {
    if (!token || isResettingCache) return;
    setIsResettingCache(true);
    try {
      const res = await fetch(`${baseUrl}powershell/resetcache`, {
        headers: getHeaders(token),
      });
      const result = await res.json();
      if (!result) return;
      refreshData();
    } catch (_) {
      // Silent error as it should not be intrusive to user's activity
    } finally {
      setCompareResults(null);
      setIsResettingCache(false);
    }
  };

  const compare = async () => {
    if (isComparing) return;
    setIsComparing(true);
    try {
      const result = await checkConfigDifferences();
      setCompareResults(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsComparing(false);
    }
  };

  useEffect(() => {
    if (!token) return;
    getManageInitialData();
  }, [getManageInitialData]);

  return (
    <ManageContext.Provider
      value={{
        filters,
        setFilters,
        filteredNumbers,
        setFilteredNumbers,
        identityData,
        setIdentityData,
        isCompleted,
        setIsCompleted,
        isSaving,
        setIsSaving,
        modifiedData,
        setModifiedData,
        searchKey,
        setSearchKey,
        currentPage,
        setCurrentPage,
        dialPlans,
        getDialPlans,
        isDialPlanLoading,
        identities,
        identitiesError,
        getIdentities,
        isIdentitiesLoading,
        numbers,
        getNumbers,
        isNumbersLoading,
        voiceRoutePolicies,
        getVoiceRoutePolicies,
        isVoiceRouteLoading,
        isMobileSuccess,
        setIsMobileSuccess,
        resetCache,
        isResettingCache,
        isStateFreeze,
        setIsStateFreeze,
        isComparing,
        compareResults,
        setCompareResults,
        compare,
        refreshData,
      }}
    >
      {!isMobile && (
        <div style={publishToolbarStyles}>
          <ManagePublishToolbar />
        </div>
      )}
      <ManageToolbar />
      <ManageTable theme={theme} />
    </ManageContext.Provider>
  );
};

export default Manage;
