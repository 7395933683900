import { Flex } from '@fluentui/react-northstar';
import { useIsMobileView } from 'hooks/useIsMobileView';
import { AppSettingsContextProvider, useAppSettings } from './Context';
import JobSyncHistory from './JobSyncHistory';
import ServicePrincipalSettings from './ServicePrincipalSettings';
import Toolbar from './Toolbar';

const Header = () => {
  const { refreshTenantData } = useAppSettings();
  return (
    <Flex gap="gap.small" vAlign="center" className="app-toolbar">
      <Toolbar handleRefreshClick={refreshTenantData} />
    </Flex>
  );
};

const ManageAppSettings = (): React.ReactElement => {
  const { isMobile } = useIsMobileView();

  return (
    <AppSettingsContextProvider>
      <Header />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '16px',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <JobSyncHistory />
        <ServicePrincipalSettings />
      </div>
    </AppSettingsContextProvider>
  );
};

export default ManageAppSettings;
