import { useHistory } from 'react-router-dom';
import { Flex, Button, Toolbar as FluentToolbar } from '@fluentui/react-northstar';
import { ChevronStartIcon, UndoIcon } from '@fluentui/react-icons-northstar';

import { ToolbarType } from 'types';

const Toolbar = ({
  loading,
  handleRefreshClick,
}: ToolbarType): React.ReactElement => {
  const history = useHistory();
  return (
    <>
      <Flex.Item>
        <Button
          content="Application Settings"
          icon={<ChevronStartIcon />}
          onClick={() => history.push('/tab')}
          text
          data-testid="btn-back"
        />
      </Flex.Item>
      <Flex.Item push>
        <section>
          <FluentToolbar
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'end',
            }}
            aria-label="Default"
            items={[
              {
                icon: (
                  <Button
                    content="Refresh"
                    icon={<UndoIcon />}
                    onClick={handleRefreshClick}
                    data-testid="btn-refresh"
                    disabled={loading}
                    text
                  />
                ),
                key: 'refresh',
              },
            ]}
          />
        </section>
      </Flex.Item>
    </>
  );
};

export default Toolbar;
